import React from "react"
import sortBy from "lodash.sortby"

export default function PositionLettersList({ files }) {
  return (
    <ul className="ml-5 list-disc list-outside">
      {sortBy(files, ["label"]).map(file => {
        return (
          <li key={file.name}>
            <a
              href={`/documents/position-letters/${file.name}`}
              target="_blank"
              rel="noreferrer"
            >
              {file.label}
            </a>
          </li>
        )
      })}
    </ul>
  )
}
