import React from "react"
import PositionLettersList from "../../components/position-letters-list"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default function PositionLetters() {
  const oppositionFiles = [
    {
      name: "2023-0420 SB423 Oppose letter.pdf",
      label: "SB 423",
    },
  ]

  const supportFiles = []

  return (
    <Layout>
      <SEO title="2023 Position Letters" />
      <h1>2023 Position Letters</h1>
      <p>
        These are the letters that CCLC submitted in OPPOSITION to state bills
        which we believe take away local control of matters related to land-use
        and zoning:
      </p>
      <PositionLettersList year={2023} files={oppositionFiles} />

      {supportFiles.length ? (
        <>
          <p>
            These are the letters that CCLC submitted in SUPPORT of state bills
            which we believe protect cities local control of matters related to
            land-use and zoning:
          </p>
          <PositionLettersList year={2023} files={supportFiles} />
        </>
      ) : (
        ""
      )}
    </Layout>
  )
}
